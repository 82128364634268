<script>
import Swal from "sweetalert2";

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            doctorId: null,
            userId: null,
            date: {
                val: "",
                isValid: true
            },
            doctor: {
                val: "",
                isValid: true
            },
            user: {
                val: "",
                isValid: true
            },
            dis: {
                val: "",
                isValid: true
            },
            med: {
                val: "",
                isValid: true
            },
            note: {
                val: "",
                isValid: true
            },
            diseases: [],
            medicines: [],
            history: [],
            id: "",
            formIsValid: true,
            docData: [],
            userData: [],
            startDate: null,
            endDate: null,
            disease: [],
            medicine: [],
            disId: [],
            medId: [],
            selectedMedicine : [],
            selectedDisease : []
        };
    },
    methods: {
        clearValidity(input) {
            this[input].isValid = true
        },
        reloadPage() {
            window.location.reload();
        },
        validateForm() {
            if (this.date.val === "") {
                this.date.isValid = false;
                this.formIsValid = false;
            }
            if (this.doctor.val === "") {
                this.doctor.isValid = false;
                this.formIsValid = false;
            }
            if (this.user.val === "") {
                this.user.isValid = false;
                this.formIsValid = false;
            }
            if (this.dis.val === "") {
                this.dis.isValid = false;
                this.formIsValid = false;
            }
            if (this.med.val === "") {
                this.med.isValid = false;
                this.formIsValid = false;
            }
            if (this.note.val === "") {
                this.note.isValid = false;
                this.formIsValid = false;
            }
            else {
                this.formIsValid = true;
            }
        },
        async getHistory() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/medical/history/"
            );
            this.medicine = response.data;
            const lastEl = this.medicine[this.medicine.length - 1];
            this.id = lastEl.id;
            this.successmsg(this.id);
        },

        async addHistory() {
            this.validateForm();
            if (!this.formIsValid) {
                this.errormsg();
                return;
            } else {
                var formdata = new FormData();
                formdata.append("doctor", this.doctorId);
                formdata.append("user", this.userId);
                formdata.append("date", this.date.val);
                formdata.append("note", this.note.val);
                formdata.append("diseases", JSON.stringify(this.selectedDisease));
                formdata.append("medicines", JSON.stringify(this.selectedMedicine));

                const result = await axios.post(
                    "https://api.doctosoft.com/index.php/api/medical/history",
                    formdata
                );
                this.msg = result.data;
                console.log(this.msg)
                this.getHistory();
                this.$router.push("/admin/prescription");
            }
        },
        async getDoctor() {
            const res = await axios.get("https://api.doctosoft.com/index.php/api/doctor/")
            this.docData = res.data
        },
        async getDisease() {
            const res = await axios.get("https://api.doctosoft.com/index.php/api/disease/")
            this.disease = res.data
        },
        async getMedicine() {
            const res = await axios.get("https://api.doctosoft.com/index.php/api/medicine/")
            this.medicine = res.data
        },
        async getUser() {
            const res = await axios.get("https://api.doctosoft.com/index.php/api/user/")
            this.userData = res.data;
        },
        errormsg() {
            Swal.fire(
                "Invalid Form",
                "Please fix the errors and try again later",
                "error"
            );
        },
        successmsg(id) {
            Swal.fire(
                "Good job!",
                "Created new prescription successfully with id " + id,
                "success"
            );
        },
    },
    created() {
        let currentDate = new Date();
        let nextDate = new Date();
        nextDate.setDate(currentDate.getDate());
        let startYear = currentDate.getFullYear();
        let startMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        let startDay = currentDate.getDate().toString().padStart(2, "0");
        let endYear = nextDate.getFullYear();
        let endMonth = (nextDate.getMonth() + 1).toString().padStart(2, "0");
        let endDay = nextDate.getDate().toString().padStart(2, "0");
        this.startDate = `${startYear}-${startMonth}-${startDay}`;
        this.endDate = `${endYear}-${endMonth}-${endDay}`;
        this.getDoctor();
        this.getUser();
        this.getDisease();
        this.getMedicine();
    }
};
</script>
<template>
    <Layout>
        <div class="back-btn">
            <div class="arrow">
                <button class="btn btn-light" @click="$router.push('/admin/prescription')"><i
                        class="mdi mdi-chevron-left"></i></button>
            </div>
            <div class="action-btn">
                <b-button class="btn-soft-info waves-effect waves-light"
                    @click="$router.push('/admin/prescription')">Cancel</b-button>
                <button class="btn  btn-md btn btn-primary" type="submit" @click="addHistory">Save</button>
            </div>
        </div>
        <b-row class="card-main">
            <b-col sm="6" xl="10">
                <b-card no-body>
                    <form class="needs-validation" @submit.prevent="addHistory">
                        <div class="mb-3" :class="{ invalid: !doctor.isValid }">
                            <label for="doctor" class="form-label">Doctor</label>
                            <select class="form-select" aria-label="size 3 select example" v-model="doctorId"
                                @blur="clearValidity('doctor')">
                                <option v-for="doc in docData" :key="doc.id" :value="doc.id">
                                    {{ doc.name }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3" :class="{ invalid: !user.isValid }">
                            <label for="user" class="form-label">User</label>
                            <select class="form-select" aria-label="size 3 select example" v-model="userId"
                                @blur="clearValidity('user')">
                                <option v-for="user in userData" :key="user.id" :value="user.id">
                                    {{ user.name }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3" :class="{ invalid: !date.isValid }">
                            <label for="date" class="form-label">Date <span class="text-danger">*</span></label>
                            <input type="date" :min="startDate" :max="endDate" v-model="date.val"
                                class="form-control" id="exampleInputdate" @blur="clearValidity('date')"/>
                        </div>

                        <div class="mb-3" :class="{ invalid: !note.isValid }">
                            <label for="note">Note <span class="text-danger"></span></label>
                            <input type="text" class="form-control" v-model="note.val" placeholder="Enter note"
                                @blur="clearValidity('note')" />
                        </div>

                        <div class="mb-3" :class="{ invalid: !dis.isValid }">
                            <label for="diseases" class="form-label">Diseases <span class="text-danger">*</span></label>
                            <select class="form-select" multiple aria-label="multiple select example" v-model="selectedDisease"
                            @blur="clearValidity('diseases')">
                                <option v-for="dis in disease" :key="dis.id" :value="dis.id">
                                    {{ dis.name }}</option>
                            </select>
                        </div>

                        <div class="mb-3" :class="{ invalid: !med.isValid }">
                            <label for="medicine" class="form-label">Medicines <span class="text-danger">*</span></label>
                            <select class="form-select" multiple aria-label="multiple select example" v-model="selectedMedicine"
                            @blur="clearValidity('medicine')">
                                <option v-for="med in medicine" :key="med.id" :value="med.id">
                                    {{ med.name }}</option>
                            </select>
                        </div>

                    </form>
                </b-card>
            </b-col>
        </b-row>
    </Layout>
</template>
